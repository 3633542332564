<template>
    <div class="text-center q-pa-md flex flex-center">
        <div>
            <div style="font-size: 32vh">
                404
            </div>

            <div class="text-h2" style="opacity:.6">
                Oops. Nothing here...
            </div>

        </div>
    </div>
</template>


<script>
    export default {
        name: 'Error404'
    }
</script>
